import NumberFormat from 'react-number-format';

import DashiSpaceTypeMetric from '../../../core/metrics/SpaceType';
import CUBProject from '../models/Project';


export default class SpaceTypeMetric extends DashiSpaceTypeMetric {

    gsf(value: any, gsf: number) {
        // @ts-ignore
        return gsf === 0 ? 0 : Math.round(Object.values(value)[0] / (gsf / 100));
    }

    //TODO we need to refactor Metrics to move the settings to a component used in the skin

    renderSettings(project: CUBProject) {
        const { before, after } = project.metrics[this.name];

        let beforeVal:number = Object.values(before)[0] as number;
        let afterVal:number = Object.values(after)[0] as number;
        return (
            <div className="SpaceTypeMetricSettings ProjectSettings-section">
                <div className="ProjectSettings-entry">
                    <label htmlFor="ProjectGSF">Project Assignable/Gross</label>
                    <div className="labelled-input">
                        <NumberFormat
                            id="ProjectGSF"
                            thousandSeparator={true}
                            value={project.gsf}
                            onValueChange={e => project.setGsf(e.floatValue || 0)}
                        />
                        <label htmlFor="ProjectGSF">%</label>
                    </div>
                </div>

                {project.type !== "New Construction" && (
                    <div className="ProjectSettings-entry">
                        <label htmlFor="">Program Use</label>
                        <div className="ProjectSettings-input">
                            <div className="ProjectSettings-cell">
                                <label htmlFor="ExistingUse">Existing Use Removed</label>
                                <select
                                    id="ExistingUse"
                                    value={Object.keys(before)[0]}
                                    onChange={e => project.setMetric(this.name, { after, before: { [e.target.value]: beforeVal }})}
                                >
                                    {this.options.map((option: string) => (
                                        <option value={option}>{option}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="ProjectSettings-cell">
                                <label htmlFor="ExistingASF">ASF</label>
                                <div className="labelled-input">
                                    <NumberFormat
                                        id="ExistingASF"
                                        thousandSeparator={true}
                                        value={beforeVal}
                                        onValueChange={e => project.setMetric(this.name, { after, before: { [Object.keys(before)[0]]: e.floatValue }})}
                                    />
                                    <label htmlFor="ExistingASF">SF/FT</label>
                                </div>
                            </div>

                            <div className="ProjectSettings-cell GSF">
                                <label htmlFor="">GSF</label>
                                <div className="cell-value">
                                    {this.gsf(before, project.gsf).toLocaleString()}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {project.type !== "Demolition" && (
                    <div className="ProjectSettings-entry">
                        <label htmlFor="">Program Use</label>
                        <div className="ProjectSettings-input">
                            <div className="ProjectSettings-cell">
                                <label htmlFor="NewUse">New Use Introduced</label>
                                <select
                                    id="NewUse"
                                    value={Object.keys(after)[0]}
                                    onChange={e => project.setMetric(this.name, { before, after: { [e.target.value]: afterVal }})}
                                >
                                    {this.options.map((option: string) => (
                                        <option value={option}>{option}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="ProjectSettings-cell">
                                <label htmlFor="NewASF">
                                    {project.type === "Renovation" ? <>&nbsp;</> : 'ASF' }
                                </label>
                                <div className="labelled-input">
                                    <NumberFormat
                                        id="NewASF"
                                        thousandSeparator={true}
                                        value={afterVal}
                                        onValueChange={e => project.setMetric(this.name, { before, after: { [Object.keys(after)[0]]: e.floatValue }})}
                                    />
                                    <label htmlFor="NewASF">SF/FT</label>
                                </div>
                            </div>

                            <div className="ProjectSettings-cell GSF">
                                <label htmlFor="">
                                    {project.type === "Renovation" ? <>&nbsp;</> : 'GSF' }
                                </label>
                                <div className="cell-value">
                                    {this.gsf(after, project.gsf).toLocaleString()}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );

    }

}
