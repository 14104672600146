import React from 'react';
import { observer } from 'mobx-react';


interface PanelSelectProps<T> {
    value: T,
    options: T[],
    onChange: (value: T) => void,
}


@observer
export default class PanelSelect<T extends string | number> extends React.Component<PanelSelectProps<T>> {
    render() {
        const { value, options, onChange } = this.props;
        return (
            <div className="PanelSelect">
                <select value={value} onChange={(event)=> onChange(event.target.value as T)}>
                    {options.map((option: T) =>
                        <option key={option} value={option}>{option}</option>)
                    }
                </select>
            </div>
        );
    }
}
