import { Store, stores } from '@strategies/stores';
import {action, computed, observable} from 'mobx';

import DashiProject from '../models/Project';
import DashiColorizerCanvas from '../models/ColorizerCanvas';
import DashiColorizerState from '../models/Colorizer';


export interface IDashiColorizerStoreSet {
    [key: string]: Map<string, DashiColorizerState>;
}

export default class DashiColorizerStore extends Store {

    @observable
    scale: number = 0.8;

    @observable
    height: number = 800;

    @observable
    width: number = 600;

    @observable
    canvases: DashiColorizerCanvas[] = [];

    @computed
    get canvasesByProject() {
        const ans: { [key: string]: string[] } = {};
        Object.keys(this.projectsByCanvas).forEach((k) => {
            const arr = this.projectsByCanvas[k];
            arr.forEach(projId => {
                if (!ans[projId]) {
                    ans[projId] = [];
                }
                ans[projId].push(k);
            });
        });
        return ans;
    }

    @computed
    get projectsByCanvas() {//TODO update for new assets structure or push to skins?
        return (
            this.canvases
                .map(canvas => ({
                    [canvas.key]: canvas.allProjectIds
                }))
                .reduce((a, b) => ({...a, ...b}), {})
        );
    }

    @action
    setScale(scale: number) {
        this.scale = scale;
    }

    @action
    setStageSize(width: number, height: number) {
        this.width = width;
        this.height = height;
    }

    @action
    addCanvas(canvas: DashiColorizerCanvas) {
        this.canvases.push(canvas);
    }

    @observable
    imageIdx: number = 0;

    @action
    setImageIdx(imageIdx: number) {
        this.imageIdx = imageIdx;
    }

    emitProjectClick(id: string | null, ctrlKey: boolean, shiftKey: boolean) {
        const {persist} = stores;

        persist.projects.forEach((project: DashiProject) => {
            if (project.id === id) {
                project.setSelected(!project.selected);
            } else {
                if (!ctrlKey) {
                    project.setSelected(false);
                }
            }
        });
    }

}
