import { useStores } from '@strategies/stores';
import {observer} from "mobx-react";
import Timeline from 'react-timeline';
import { RadioSlider } from '@strategies/react-form';

import Filters from '../Filters';
import SlidersIcon from '../icons/Sliders';
import TimeSlider from "../TimeSlider/TimeSlider";
import {ProjectList} from "./ProjectList";
import PanelSelect from "../PanelSelect";
import { fixedRectStyle } from '../../stores/LayoutStore';


export default observer(function TimelinePanel() {
    const { app, config, layout, persist, ui } = useStores();
    const extended = ui.timelinePanelIsOpen && !ui.chartsPanelIsOpen;

    return (
        <div className={`TimelinePanel`} style={fixedRectStyle(layout.timelinePanel)}>
            <div className={`panel tPanel ${!ui.timelinePanelIsOpen ? 'panel--closed' : ''} ${extended ? 'panel--extended' : ''}`}>
                <div className="panel__header">
                    <div className="panel-toggle">
                        <button onClick={() => ui.setTimelinePanelOpen(!ui.timelinePanelIsOpen)}>
                            <SlidersIcon />
                        </button>

                        {ui.timelinePanelIsOpen && (
                            <PanelSelect
                                value={app.timelineEnabled ? 'Timeline' : 'Project Cards'}
                                options={['Timeline', 'Project Cards']}
                                onChange={mode => app.setTimelineEnabled(mode === 'Timeline')}
                            />
                        )}
                    </div>

                    <TimeSlider/>

                    <div className="color-selector">
                        <RadioSlider
                            value={app.colorMode.split('_').join(' ')}
                            options={Object.keys(config.colorBy).map(x => x.split('_').join(' '))}
                            onChange={mode => app.setColorMode(mode.split(' ').join('_'))}
                        />
                    </div>

                    <div className="timeline-menu">
                        <div className="menu-hamburger">
                            <div className="dot" />

                            <span>Menu</span>
                        </div>

                        <ul>
                            <li>
                                <button onClick={() => app.triggerBlockSort()}>Sort Blocks</button>
                            </li>
                        </ul>
                    </div>
                </div>

                {ui.timelinePanelIsOpen && <>
                    <Filters/>

                    <div className={"timelineHolder"}>
                        {app.timelineEnabled ? <Timeline
                            key={app.projectsKey}
                            blocks={app.blocks}
                            blockHeight={config.timelineBlockHeight}
                            editor={'LockedRowEditor'}
                            startYear={config.startYear}
                            rowPadding={config.timelineRowPadding}
                            scrubber={app.scrubber}
                            defaultViewportWidth={(config.totalPeriods * .25) * config.periodScale}
                            timelineLock={false}
                            onBlockCreate={block => {
                                const project = app.createProjectFromBlock(block);
                                project.setSelected();
                                ui.setProjectSettingsOpen();

                                persist.addProject(project);
                            }}
                            onScrubberChange={scrubber => app.setScrubber(scrubber)}
                        /> : <ProjectList/>}
                    </div>
                </>}
            </div>
        </div>
    );
});
