import React, {FunctionComponent} from "react";
import {observer} from "mobx-react";

export type CheckboxProps = {
    value: boolean;
    onClick: () => void;
};
const Checkbox: FunctionComponent<CheckboxProps> = ({onClick, value}: CheckboxProps) => {
    return (<button className={'Checkbox' + (value ? ' checked' : '')} onClick={() => onClick()}/>)
}

export default observer(Checkbox);
