import { time } from 'react-timeline';
import { stores } from '@strategies/stores';
import { ExtendedModel ,model, modelAction } from 'mobx-keystone';

import DemoAppStore from '../../cu-boulder/stores/AppStore';
import DashiPersistStore from '../../../core/stores/PersistStore';

import data from '../assets/projects.json';

const campus: { [key:string]: string} = {
    'Main': 'Main Campus',
    'Grandview': 'Main Campus',
    'East': 'East Campus',
    'WillVill': 'South Campus',
    'NBC': 'North Campus',
};


@model('demo/PersistStore')
class DemoPersistStore extends ExtendedModel(DashiPersistStore, {}) {

    @modelAction
    seed() {
        const { app, config } = stores;

        this.projects = data.map(r => {
            const metrics: any = {};
            const SpaceTypePayload: any = {};

            Object.values(r.metrics.payloads).forEach(payload => {
                if (payload.spaceType) {
                    if (payload.group === 'payloadsRemoved') {
                        SpaceTypePayload.before = {
                            ...SpaceTypePayload.before,
                            [payload.spaceType]: payload.value,
                        }
                    }
                    else {
                        SpaceTypePayload.after = {
                            ...SpaceTypePayload.after,
                            [payload.spaceType]: payload.value,
                        }
                    }
                }
                else {
                    metrics['Cost'] = app.metrics['Cost'].generatePayload(payload.value);
                }
            });

            metrics['SpaceType'] = app.metrics['SpaceType'].generatePayload(SpaceTypePayload);

            const timestamps = r.projectType === 'Existing' ? ({
                    start: 6 * time.MONTH,
                    end: 18 * time.MONTH,
                    design: 0,
                }) : ({
                    start: r.constructionStartPeriod * config.periodScale,
                    end: r.constructionEndPeriod * config.periodScale,
                    design: r.designPeriod * config.periodScale,
            });

            return (app as DemoAppStore).createProject({
                id: r.projectId,
                funding: 'Capital General',
                metrics,
                name: r.name,
                isExcluded: r.projectType === 'Existing',
                _campus: campus[r.campus],
                type: r.projectType === 'Existing' ? 'Renovation' : r.projectType,
                timestamps,
            });
        });
    }

}

export default DemoPersistStore;
