import DashiProject from './Project';

export type MetricPayload = {
    [key: string]: number;
};

export enum MetricBehavior {
    Before,
    During,
    After,
};

export default class DashiMetric {

    label: string;

    constructor(label: string) {
        this.label = label;
    }

    name = 'Base Metric';

    generatePayload(..._: any[]): object {
        throw new Error('`generatePayload` not implemented');
    }

    getValue(_: MetricBehavior, __: any, ___: number): MetricPayload {
        throw new Error('`getValue` not implemented');
    }

    renderSettings(_: DashiProject) {
        throw new Error('`renderSettings` not implemented');
    }

    displayUnits(value: number) {
        return `${value} units`;
    }

}
