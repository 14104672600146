import { stores } from '@strategies/stores';
import {override, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';

import StackedAreaChart from '../../../core/ui/charts/StackedAreaChart';
import DashiChart, {DashiChartProps} from "../../../core/ui/charts/Chart";
import MetricView, {KvpMetricView} from "../../../core/models/MetricView";
import Project from "../models/Project";

export const getSpaceType = (p: Project) => {
    let preferredSide = 'after';
    if (p.type === 'Demolition') {
        preferredSide = 'before'
    }
    return Object.keys(p.metrics['SpaceType'][preferredSide])[0];
};

@observer
class UseByASF extends StackedAreaChart {


    constructor(props: DashiChartProps) {
        super(props);

        this.metricView = new KvpMetricView((p) => (p as Project).chartableUse);

        makeObservable(this);
    }

    formatValue(value: number): string {
        return Math.round(value).toLocaleString();
    }

    static get label() {
        return 'Use & ASF';
    }

    static get title() {
        return 'Program Use over Time vs ASF';
    }

    static selectedOnLoad = true;

    x = 'TIME';
    y = 'ASF';

    get colors() {
        return stores.config.SpaceTypeColors;
    }
}

export default UseByASF;
