import CUBAppStore from '../../cu-boulder/stores/AppStore';

import UseByASF from '../../cu-boulder/charts/UseByASF';
import TypeByASF from '../../cu-boulder/charts/TypeByASF';
import TypeDonut from '../charts/TypeDonut';
import FundingByASF from '../../cu-boulder/charts/FundingByASF';
import FundingByCost from '../../cu-boulder/charts/FundingByCost';
import UseDonut from "../../cu-boulder/charts/UseDonut";


export default class AppStore extends CUBAppStore {

    constructor() {
        super();
        
        this.colorMode = 'use';

        this.charts = [
            UseByASF,
            TypeByASF,
            FundingByCost,
            FundingByASF,
            TypeDonut,
            UseDonut
        ];
    }
    

}
