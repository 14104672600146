import { stores } from '@strategies/stores';
import {override, makeObservable, observable} from 'mobx';
import { observer } from 'mobx-react';

import StackedAreaChart from '../../../core/ui/charts/StackedAreaChart';
import MetricView from "../../../core/models/MetricView";
import {DashiChartProps} from "../../../core/ui/charts/Chart";
import Project from "../models/Project";


@observer
class FundingByASF extends StackedAreaChart {

    constructor(props: DashiChartProps) {
        super(props);
        this.metricView = new MetricView((p) => (p as Project).begunASF, (p) => (p as Project).funding);
        makeObservable(this);
    }

    formatValue(value: number): string {
        return Math.round(value).toLocaleString();
    }

    static get label() {
        return 'Funding & ASF';
    }

    static get title() {
        return 'Funding Type over Time vs ASF';
    }

    x = 'TIME';
    y = 'ASF';

    get colors() {
        return stores.config.ProjectFundingColors;
    }

}

export default FundingByASF;
