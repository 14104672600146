import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Modal from '@strategies/react-modal';
import { useStores } from '@strategies/stores';


export default observer(function ScenarioModal({ active, onClose, onSave, title }) {
    const { persist } = useStores();
    const [scenarioName, setScenarioName] = useState(persist.scenarioName);
    const [description, setDescription] = useState(persist.description);

    useEffect(() => {
        setScenarioName(persist.scenarioName);
        setDescription(persist.description);
    }, [persist.scenarioName, persist.description])

    const save = useCallback(() => {
        persist.setScenarioName(scenarioName);
        persist.setDescription(description);

        onSave();
        onClose();
    }, [persist, scenarioName, description, onSave, onClose]);

    const cancel = useCallback(() => {
        setScenarioName(persist.scenarioName);
        setDescription(persist.description);

        onClose()
    }, [persist.scenarioName, persist.description, setScenarioName, setDescription, onClose]);

    return (
        <Modal className={`ScenarioModal ${active ? 'active' : ''}`} onClose={() => cancel()}>
            <header>{title}</header>

            <div className="content">
                <div className='input'>
                    <label htmlFor="SaveScenarioName">Scenario Name</label>
                    <input
                        id="SaveScenarioName"
                        placeholder="Name your new scenario ..."
                        value={scenarioName}
                        onChange={e => setScenarioName(e.target.value)}
                    />
                </div>

                <div className='input'>
                    <label htmlFor="SaveDescription">Description</label>
                    <input
                        id="SaveDescription"
                        placeholder="Describe this scenario ..."
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                    />
                </div>

                <div className="modal-buttons">
                    <button className="secondary" onClick={cancel}>Cancel</button>

                    <button
                        className={`primary ${scenarioName === '' ? 'disabled' : ''}`}
                        onClick={save}
                    >
                       Save Scenario
                    </button>
                </div>
            </div>
        </Modal>
    );
});
