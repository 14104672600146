import { Store, stores } from '@strategies/stores';
import {action, computed, makeObservable, observable} from 'mobx';

import DashiChart from '../ui/charts/Chart';


export enum LayoutMode {
    A,
    B,
}

export interface ISize {
    width: number;
    height: number;
}

export interface IPosition {
    x: number;
    y: number;
}

export interface IRect {
    position: IPosition;
    size: ISize;
}

export type ChartSlot = {
    chart?: typeof DashiChart;
    rect?: any;
};

export const fixedRectStyle = (rect: IRect) => {
    return {
        top: rect.position.y,
        left: rect.position.x,
        width: rect.size.width,
        height: rect.size.height,
    }
}

export default class DashiLayoutStore extends Store {

    constructor() {
        super();

        window.addEventListener('resize', () => {
            this.setSize(window.innerWidth, window.innerHeight);
        })
        this.setSize(window.innerWidth, window.innerHeight);
        makeObservable(this);
    }

    @observable
    width: number = window.innerWidth;

    @observable
    height: number = window.innerHeight;

    @observable
    timelinePanelMinWidth: number = 850;

    @observable
    timelinePanelCollapsedHeight: number = 60;

    @observable
    chartPanelMinHeight: number = 425;

    @observable
    chartMinWidth: number = 525;

    @computed
    get chartPanelHeight(): number {
        return Math.max(0.33 * this.height, this.chartPanelMinHeight);
    }

    @computed
    get timelinePanelWidth(): number {
        return Math.max(0.33 * this.width, this.timelinePanelMinWidth);
    }

    @computed
    get chartsPanel(): IRect {
        const { ui } = stores;
        const chPanelY = this.height - this.chartPanelHeight;

        const fullChartRect = {
            position: {x: 0, y: chPanelY},
            size: {width: this.width, height: this.chartPanelHeight}
        };

        if (!ui.timelinePanelIsOpen) {
            return fullChartRect;
        }

        if (!ui.chartsPanelIsOpen) {
            fullChartRect.size.width = this.width - this.timelinePanelWidth;
            return fullChartRect;
        }

        //cases here
        if (this.layoutMode === LayoutMode.B) {
            fullChartRect.size.width = this.width - this.timelinePanelWidth;
        }

        return fullChartRect;
    }

    @computed
    get colorizerWindow(): IRect {
        const { ui }  = stores;

        return {
            position: {
                x: 0,
                y: 0,
            },
            size: {
                width: this.width - (ui.timelinePanelIsOpen ? this.timelinePanelWidth : 0),
                height: this.height - (ui.chartsPanelIsOpen ? this.chartPanelHeight : 0),
            }
        };
    }

    @computed
    get selectionInfoRect(): IRect {
        const { ui } = stores;
        const width = Math.max(216, Math.min(350, this.colorizerWindow.size.width * 0.25));
        const height = 500;

        return {
            position: {
                x: this.colorizerWindow.position.x + this.colorizerWindow.size.width - width,
                y: ui.timelinePanelIsOpen ? 0 : this.timelinePanelCollapsedHeight,
            },
            size: {
                width: width,
                height: height,
            }
        };
    }

    @computed
    get timelinePanel(): IRect {
        const { ui } = stores;

        const tPanelX = this.width - this.timelinePanelWidth;
        const fullTimelineRect = {
            position: {x: tPanelX, y: 0},
            size: {width: this.timelinePanelWidth, height: this.height}
        };
        if (!ui.timelinePanelIsOpen) {
            fullTimelineRect.size.height = this.timelinePanelCollapsedHeight;
            return fullTimelineRect;
        }

        if (!ui.chartsPanelIsOpen) {
            return fullTimelineRect;
        }
        //cases here
        if (this.layoutMode === LayoutMode.A) {
            fullTimelineRect.size.height = this.height - this.chartPanelHeight;
        }
        return fullTimelineRect;
    }

    @computed
    get innerChartArea(): IRect {
        return {
            position: {
                x: 0,
                y: 0,
            },
            size: {
                width: this.chartsPanel.size.width - 40,//TODO can this be derived from DOM state?
                height: this.chartsPanel.size.height - 70,
            }
        }
    }

    @computed
    get charts(): ChartSlot[] {
        const slots: ChartSlot[] = [];
        const { app: { charts }, ui } = stores;
        let spaceAvailable = this.innerChartArea.size.width;

        const activeCharts = charts.filter((chart: typeof DashiChart) => !chart.isDisabled && ui.isChartSelected(chart));

        for (let i = 0; i < activeCharts.length; i++) {
            const chart = activeCharts[i];

            if (spaceAvailable - chart.requiredWidth < 0) {
                break;
            }

            slots.push({
                chart: activeCharts[i],
                rect: chart.aspect ? ({
                    flex: `0 0 ${chart.requiredWidth}px`,
                }) : undefined
            });

            spaceAvailable -= chart.requiredWidth;
        }

        return slots;
    }

    @computed
    get requiredChartsWidth() {
        // @ts-ignore
        return this.charts.reduce((a,b) => a + b.chart.requiredWidth, 0);
    }

    @computed
    get aspectRatio(): number {
        return this.width / this.height;
    }

    @computed
    get layoutMode(): LayoutMode {
        if (this.aspectRatio < 1.7) {//4:3
            return LayoutMode.A;
        } else {
            return LayoutMode.B;
        }
    }

    @action
    setSize(width: number, height: number) {
        this.width = width;
        this.height = height;
    }

}
