import { useStores } from  '@strategies/stores';
import { observer } from 'mobx-react';

import ScenarioModal from './ScenarioModal';


export default observer(function SaveAsModal() {
    const { persist, ui } = useStores();

    return (
        <ScenarioModal
            title={'Save As'}
            active={ui.saveAsModalIsOpen}
            onSave={() => {
                persist.branch();
                persist.save();
            }}
            onClose={() => ui.setSaveAsModalOpen(false)}
        />
    );
});
