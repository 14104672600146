import { stores } from '@strategies/stores';
import {override, makeObservable, observable} from 'mobx';
import { observer } from 'mobx-react';

import StackedAreaChart, {AnnualStackedAreaChart} from '../../../core/ui/charts/StackedAreaChart';
import MetricView from "../../../core/models/MetricView";
import {DashiChartProps} from "../../../core/ui/charts/Chart";
import Project from "../models/Project";

@observer
class FundingByCost extends AnnualStackedAreaChart {

    constructor(props: DashiChartProps) {
        super(props);
        this.metricView = new MetricView((p) => (p as Project).chartableCost, (p) => (p as Project).funding);
        makeObservable(this);
    }

    @override
    get data() {
        return this.metricView.cumulativeTotalsByTypeByYear;
    }

    formatValue(value: number): string {
        return Math.round(value/1000000).toLocaleString();
    }

    static get label() {
        return 'Funding & Cost';
    }

    static get title() {
        return 'Funding Type over time vs Esc. Total Cost';
    }

    x = 'TIME';
    y = 'ESC. TOTAL COST ($ mm)';

    get colors() {
        return stores.config.ProjectFundingColors;
    }

}

export default FundingByCost;
