import { useState } from 'react';
import { observer } from "mobx-react";
import { useStores } from '@strategies/stores';

import Logo from '../assets/logo.svg';

import InfoIcon from '../icons/Info';
import SaveIcon from '../icons/Save';
import FolderIcon from '../icons/Folder';
import ChevronIcon from '../icons/Chevron';


let timeout;

export default observer(function MenuBar() {
    const [showSave, setShowSave] = useState(false);
    const [saving, setSaving] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const { app, config, layout, persist, ui } = useStores();

    const expandable = layout.timelinePanel.size.width + (app.selectedProjects.length === 1 ? layout.selectionInfoRect.size.width : 0) >= layout.width - 710;

    return (
        <div className={`MenuBar ${expandable ? 'expandable' : ''} ${expandable && expanded ? 'expanded': ''}`}>
            <div className="ribbon">
                <div className="title">
                    <img src={Logo} alt="Dashi Logo" />
                    <h4>{config.project}</h4>
                </div>

                <div className="file-actions">
                    <button onClick={() => ui.setInfoModalOpen()}>
                        <InfoIcon />
                    </button>

                    <button onClick={() => ui.setFileModalOpen()}>
                        <FolderIcon />
                    </button>

                    <div className="scenario-name-wrapper">
                        <div className="scenario-name">
                            <label htmlFor="scenario">Scenario</label>
                            <input id="scenario" placeholder="Scenario Name ..." value={persist.scenarioName} onChange={v => persist.setScenarioName(v.target.value)} />
                        </div>
                    </div>

                    <button
                        className="show-save-toggle"
                        onMouseEnter={() => setShowSave(true)}
                        onMouseLeave={() => { clearTimeout(timeout); timeout = setTimeout(() => setShowSave(false), 500)}}
                    >
                        <SaveIcon />
                    </button>

                    {expandable && (
                        <button className='expand-toggle' onClick={() => setExpanded(!expanded)}>
                                <ChevronIcon />
                                <ChevronIcon />
                        </button>
                    )}
                </div>
            </div>

            <div className={`SaveOptions ${showSave ? 'active' : ''}`}>
                <button
                    className={`primary ${!persist.exists || saving ? 'disabled' : ''}`}
                    disabled={!persist.exists || saving}
                    onClick={() => {
                        setSaving(true);
                        setTimeout(() => setSaving(false), 2000);
                        persist.save()
                    }}
                >
                    {saving ? 'Saving ...' : 'Save'}
                </button>

                <button className="secondary" onClick={() => ui.setSaveAsModalOpen()}>
                    Save As
                </button>
            </div>
        </div>
    );
});
