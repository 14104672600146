import {observer} from "mobx-react";
import { useStores } from '@strategies/stores';

import SelectionInfo from "../../../core/ui/SelectionInfo";


export default observer(function DisplaySelectionInfo() {
    const {app, ui} = useStores();
    const panelTitle = "Selection Overview";

    if (!app.selectedProject) return null;

    if (app.selectedProjects.length > 1) {
        const selectedAndExcluded = app.selectedProjects.filter(project => project.isExcluded);
        const info = [
            { name: 'Selected', value: `${app.selectedProjects.length}` },
            { name: 'ASF', value: app.selectedProjects.reduce((a,b) => a + b.asf, 0).toLocaleString() },
        ];

        const buttons = [];
        if (selectedAndExcluded.length > 0) {
            if (selectedAndExcluded.length !== app.selectedProjects.length) {
                info.push({name: 'Excluded', value: `${selectedAndExcluded.length}`},);
            }
            buttons.push({
                label: 'Add (' + selectedAndExcluded.length + ') Selected',
                onClick: () => {
                    selectedAndExcluded.forEach(project => {
                        project.setIsExcluded(false);
                    });
                }
            });
        }

        return (
            <SelectionInfo
                title={panelTitle}
                info={info}
                buttons={buttons}
            />
        );
    }

    const info = [
        { name: 'Name', value: app.selectedProject.name },
        { name: 'Use', value: app.selectedProject.use },
        { name: 'ASF', value: app.selectedProject.asf.toLocaleString() },
    ];
    if (app.selectedProject.filteredOut) {
        info.push({name: 'Filtered out', value: 'by '+app.selectedProject.reasonWhyFilteredOut});
    }

    return (
        <SelectionInfo
            title={panelTitle}
            info={info}
            buttons={[
                {
                    label: 'View Data',
                    onClick: () => {
                        app.selectedProject.snapshot();
                        ui.setProjectSettingsOpen(true);
                    }
                },
                ...app.selectedProject.filteredOut ? [] : [{
                    label: app.selectedProject.isExcluded ? 'Add' : 'Remove',
                    onClick: () => {
                        app.selectedProject.setIsExcluded(!app.selectedProject.isExcluded);
                    }
                }]
            ]}
        />
    );
});
