import { observer } from "mobx-react";
import { List } from 'react-feather';

export default observer(function ChartHeader({ data, title }) {
    return (
        <div className="ChartsHeader">
            <h5>{title}</h5>

            {data && (
                <div className="chart-legend">
                    <ul>
                        {data.datasets.map(({ backgroundColor, label }, i) => (
                            <li key={`${i}_${label}`}>
                                <div style={{ backgroundColor }} />
                                <span>{label}</span>
                            </li>
                        ))}
                    </ul>

                    <List />
                </div>
            )}
        </div>
    );
});
