import { stores } from '@strategies/stores';
import {override, makeObservable, observable} from 'mobx';
import { observer } from 'mobx-react';

import DonutChart from '../../../core/ui/charts/DonutChart';
import MetricView, {AMetricView, KvpMetricView} from "../../../core/models/MetricView";
import Project from "../models/Project";
import {getSpaceType} from "./UseByASF";


@observer
class UseDonut extends DonutChart {
    @observable
    protected metricView: AMetricView;

    constructor(props: any) {
        super(props);
        this.metricView = new KvpMetricView((p) => (p as Project).chartableUse);
        makeObservable(this);
    }

    static get label() {
        return 'Use & Time';
    }

    static get title() {
        return 'Project Use at Time';
    }

    static selectedOnLoad = false;

    formatValue(value: number): string {
        return Math.round(value).toLocaleString();
    }

    @override
    get data() {
        return [this.metricView.currentByType];
    }

    get colors() {
        return stores.config.SpaceTypeColors;
    }

}

export default UseDonut;
