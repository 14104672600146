import App from './App';
import './index.scss';
import { bootstrap } from '../../core';
import firebaseConfig from './firebaseConfig';

import config from './config';
import AppStore from '../demo/stores/AppStore';
import PersistStore from '../demo/stores/PersistStore';
import UIStore from '../demo/stores/UIStore';
import ColorizerStore from '../demo/stores/ColorizerStore';

bootstrap(firebaseConfig, config, {
    persist: new PersistStore({}),
    app: new AppStore(),
    colorizer: new ColorizerStore(),
    ui: new UIStore(),
});

export default App;
