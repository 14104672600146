import { stores } from '@strategies/stores';
import {override, makeObservable, observable} from 'mobx';
import { observer } from 'mobx-react';

import DonutChart from '../../../core/ui/charts/DonutChart';
import MetricView from "../../../core/models/MetricView";
import Project from "../models/Project";


@observer
class TypeDonut extends DonutChart {
    @observable
    protected metricView: MetricView;

    constructor(props: any) {
        super(props);
        this.metricView = new MetricView((p) => (p as Project).begunASF, (p) => (p as Project).type);
        makeObservable(this);
    }

    static get label() {
        return 'Type & Time';
    }

    static get title() {
        return 'Project Type at Time';
    }

    static selectedOnLoad = true;

    formatValue(value: number): string {
        return Math.round(value).toLocaleString();
    }

    @override
    get data() {
        return [this.metricView.currentByType];
    }

    get colors() {
        return stores.config.ProjectTypeColors;
    }

}

export default TypeDonut;
