import { register } from '@strategies/stores';

import DefaultDashiConfig  from './config';
import FirebaseConfig from './types/FirebaseConfig';
import ConfigStore from './stores/ConfigStore';
import DashiAppStore from './stores/AppStore';
import DashiColorizerStore  from './stores/ColorizerStore';
import DashiPersistStore from './stores/PersistStore';
import DashiUserStore from './stores/UserStore';
import DashiUIStore from './stores/UIStore';
import DashiLayoutStore from './stores/LayoutStore';


type TopLevelStores = {
    app?: DashiAppStore;
    persist?: DashiPersistStore;
    user?: DashiUserStore;
    colorizer?: DashiColorizerStore;
    ui?: DashiUIStore;
    layout?: DashiLayoutStore;

};

export function bootstrap(firebaseConfig: FirebaseConfig, config: any = {}, stores: TopLevelStores = {}) {
    register({ 
        persist: stores.persist || new DashiPersistStore({}),
        app: stores.app || new DashiAppStore(),
        colorizer: stores.colorizer || new DashiColorizerStore(),
        user: stores.user || new DashiUserStore(),
        ui: stores.ui || new DashiUIStore(),
        layout: stores.layout || new DashiLayoutStore(),
        config: new ConfigStore({ ...firebaseConfig, ...DefaultDashiConfig, ...config }) 
    });
}
