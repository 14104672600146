import {observer} from "mobx-react";
import { useStores } from '@strategies/stores';
import Checkbox from "./Checkbox";
import RailsProject from "../../../skins/philly-rails/models/Project";
import DashiProject from "../../models/Project";

export const formatDollars = (val: number) => {
    return '$' + (Math.round(val / 10000) * 10000).toLocaleString();
};

const ProjectCard = observer(({project}: { project: DashiProject }) => {
    const {app, config, persist} = useStores();

    // @ts-ignore
    const activeValue = project[app.colorMode]

    return (<div
        className={'ProjectCard' + (project.selected ? ' selected' : '') + (project.isExcluded ? ' disabled' : '')}>
        <div className="clickable-title" style={{backgroundColor: project.color}}>
            <h3 onClick={(e) => app.selectProject(project, e.ctrlKey)}>{project.displayName}</h3>
            <Checkbox value={!project.isExcluded} onClick={() => project.setIsExcluded(!project.isExcluded)}/>
        </div>
        <div className="inner">
            <div className={'data-element row'}>
                <div className={'name'}>
                    {
                        app.colorMode.split('_').join(' ') //CODE-REVIEW - we should centralize the display names - not use string operations
                    }
                </div>
                <div className={'value'}>
                    {activeValue}
                </div>
            </div>
            {project.cardInfo &&
                project.cardInfo.map(({label, value}) =>
                    <div key={label} className={'data-element row'}>
                        <div className={'name'}>
                            {label}
                        </div>
                        <div className={'value'}>
                            {value}
                        </div>
                    </div>
                )}
        </div>
    </div>)
});

export const ProjectList = observer(() => {
    const {app} = useStores();
    const {projects} = app;

    return (
        <div className={'ProjectList'}>
            {projects.map((project: DashiProject) => <ProjectCard key={project.id} project={project as RailsProject}/>)}
        </div>
    );
});
