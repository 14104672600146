const firebaseConfig = {
  apiKey: "AIzaSyA2Ysa3HpW1HpIRZj9t3qU7wn5nL5csWnY",
  authDomain: "arcadis-dashi.firebaseapp.com",
  projectId: "arcadis-dashi",
  storageBucket: "arcadis-dashi.appspot.com",
  messagingSenderId: "698095053214",
  appId: "1:698095053214:web:52e8a997c4b08d022cce8f"
};

export default firebaseConfig;
