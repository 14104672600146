import DashiColorizerStore from '../../../core/stores/ColorizerStore';
import DashiColorizerCanvas from '../../../core/models/ColorizerCanvas';
import {ColorizerLayer,GraphicIdLayer,ImageLayer} from "../../../core/models/ColorizerImageLayer";

import NBC_base from '../../cu-boulder/assets/img/NBC_Campus/Existing_Base.jpg';
import NBC_landscapeId from '../../cu-boulder/assets/img/NBC_Campus/landscape/id.png';
import NBC_landscapeKey from '../../cu-boulder/assets/img/NBC_Campus/landscape/id.png-keyIndex.json';
import NBC_landscapeGraphic from '../../cu-boulder/assets/img/NBC_Campus/landscape/graphic.jpg';
import NBC_buildingGraphic from '../../cu-boulder/assets/img/NBC_Campus/building/output.png';
import NBC_buildingKey from '../../cu-boulder/assets/img/NBC_Campus/building/data.png-keyIndex.json';
import NBC_buildingData from '../../cu-boulder/assets/img/NBC_Campus/building/data.png';

import East_base from '../../cu-boulder/assets/img/EastCampus/Existing_Base.jpg';
import East_landscapeId from '../../cu-boulder/assets/img/EastCampus/landscape/id.png';
import East_landscapeKey from '../../cu-boulder/assets/img/EastCampus/landscape/id.png-keyIndex.json';
import East_landscapeGraphic from '../../cu-boulder/assets/img/EastCampus/landscape/graphic.jpg';
import East_buildingGraphic from '../../cu-boulder/assets/img/EastCampus/building/output.png';
import East_buildingKey from '../../cu-boulder/assets/img/EastCampus/building/data.png-keyIndex.json';
import East_buildingData from '../../cu-boulder/assets/img/EastCampus/building/data.png';

import Main_base from '../../cu-boulder/assets/img/MainCampus/Existing_Base.jpg';
import Main_landscapeId from '../../cu-boulder/assets/img/MainCampus/landscape/id.png';
import Main_landscapeKey from '../../cu-boulder/assets/img/MainCampus/landscape/id.png-keyIndex.json';
import Main_landscapeGraphic from '../../cu-boulder/assets/img/MainCampus/landscape/graphic.jpg';
import Main_buildingGraphic from '../../cu-boulder/assets/img/MainCampus/building/output.png';
import Main_buildingKey from '../../cu-boulder/assets/img/MainCampus/building/data.png-keyIndex.json';
import Main_buildingData from '../../cu-boulder/assets/img/MainCampus/building/data.png';

import Will_base from '../../cu-boulder/assets/img/WillVill/Existing_Base.jpg';
import Will_landscapeId from '../../cu-boulder/assets/img/WillVill/landscape/id.png';
import Will_landscapeKey from '../../cu-boulder/assets/img/WillVill/landscape/id.png-keyIndex.json';
import Will_landscapeGraphic from '../../cu-boulder/assets/img/WillVill/landscape/graphic.jpg';
import Will_buildingGraphic from '../../cu-boulder/assets/img/WillVill/building/output.png';
import Will_buildingKey from '../../cu-boulder/assets/img/WillVill/building/data.png-keyIndex.json';
import Will_buildingData from '../../cu-boulder/assets/img/WillVill/building/data.png';


export default class CUBColorizerStore extends DashiColorizerStore {

    constructor() {
        super();

        this.setStageSize(5200, 3000);

        (() => {
            const width = 2160;
            const height = 1147;

            this.addCanvas(new DashiColorizerCanvas({ 
                title: 'North Campus', 
                width,
                height,
                scale: 0.9,
                layers: [
                    new ImageLayer(NBC_base, width, height),
                    new GraphicIdLayer(width, height, NBC_landscapeGraphic, NBC_landscapeId, NBC_landscapeKey),
                    new ColorizerLayer(width, height, NBC_buildingGraphic, NBC_buildingData, NBC_buildingKey),
                ]
            }));
        })();

        (() => {
            const width = 2400;
            const height = 1482;

            this.addCanvas(new DashiColorizerCanvas({ 
                title: 'East Campus', 
                width,
                height,
                scale: 0.9,
                layers: [
                    new ImageLayer(East_base, width, height),
                    new GraphicIdLayer(width, height, East_landscapeGraphic, East_landscapeId, East_landscapeKey),
                    new ColorizerLayer(width, height, East_buildingGraphic, East_buildingData, East_buildingKey),
                ]
            }));
        })();

        (() => {
            const width = 2401;
            const height = 1289;

            this.addCanvas(new DashiColorizerCanvas({ 
                title: 'Main Campus', 
                width,
                height,
                scale: 1.05,
                layers: [
                    new ImageLayer(Main_base, width, height),
                    new GraphicIdLayer(width, height, Main_landscapeGraphic, Main_landscapeId, Main_landscapeKey),
                    new ColorizerLayer(width, height, Main_buildingGraphic, Main_buildingData, Main_buildingKey),
                ]
            }));
        })();

        (() => {
            const width = 2400;
            const height = 1419;

            this.addCanvas(new DashiColorizerCanvas({ 
                title: 'South Campus', 
                width,
                height,
                scale: 0.8,
                layers: [
                    new ImageLayer(Will_base, width, height),
                    new GraphicIdLayer(width, height, Will_landscapeGraphic, Will_landscapeId, Will_landscapeKey),
                    new ColorizerLayer(width, height, Will_buildingGraphic, Will_buildingData, Will_buildingKey),
                ]
            }));
        })();
    }

}
