import { useStores } from  '@strategies/stores';
import { observer } from 'mobx-react';

import ScenarioModal from './ScenarioModal';


export default observer(function UpdateScenarioModal() {
    const { persist, ui } = useStores();

    return (
        <ScenarioModal
            title={'Update Scenario Info'}
            active={ui.updateScenarioModalIsOpen}
            onSave={() => persist.save()}
            onClose={() => ui.setUpdateScenarioModalOpen(false)}
        />
    );
});
