import {observer} from "mobx-react";
import {Menu, MenuButton, MenuItem, MenuHeader} from "@szhsin/react-menu";
import {Globe, List} from 'react-feather';
import '@szhsin/react-menu/dist/index.css';
import { useStores } from '@strategies/stores';

import PlusIcon from '../icons/Plus';
import MinusIcon from '../icons/Minus';
import ExpandIcon from '../icons/Expand';


export default observer(function ToolBar({zoomIn, zoomOut, menuButtons}) {
    const { app, config } = useStores();
    const {ui} = app;

    const togglePanels = () => {
        const bothOpen = !ui.timelinePanelIsOpen && !ui.chartsPanelIsOpen;
        ui.setChartsPanelOpen(bothOpen);
        ui.setTimelinePanelOpen(bothOpen);
    };

    const colorCategories = Object.entries(config[config.colorBy[app.colorMode]]);

    return (
        <div className="ToolBar panel">
            <button onClick={zoomIn}><PlusIcon/></button>
            <button onClick={zoomOut}><MinusIcon/></button>
            <button onClick={togglePanels}><ExpandIcon/></button>
            {menuButtons.map((menuButton, i) => (
                menuButton.items.length === 1 ?
                    <button onClick={() => menuButton.items[0].onClick()}><Globe/></button> :
                    <Menu
                        key={i}
                        direction={'right'}
                        menuButton={<MenuButton><Globe/></MenuButton>}
                    >
                        {menuButton.items.map(item => (
                            <MenuItem key={item.name} onClick={() => item.onClick()}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Menu>
            ))}

            <Menu
                className="no-hover"
                direction={'right'}
                menuButton={<MenuButton><List/></MenuButton>}
            >
                {config.legend && Object.entries(config.legend).map(([item, icon]) => (
                    <MenuItem key={item} disabled>
                        <img src={icon} alt={item} /> {item}
                    </MenuItem>
                ))}

                <MenuHeader>{app.colorMode}</MenuHeader>
                {colorCategories.map(([item, backgroundColor]) => (
                    <MenuItem key={item} disabled>
                        <span style={{ backgroundColor }} /> {item}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
});
