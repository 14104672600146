import { stores } from '@strategies/stores';
import merge from 'lodash.merge';
import {computed, makeObservable, observable, override} from 'mobx';
import {Chart} from 'chart.js';
import {Line} from "react-chartjs-2";
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import annotationPlugin from 'chartjs-plugin-annotation';

import DashiChart from './Chart';
import {formatLineDataSet} from "./chartFunctions";
import ChartHeader from "./ChartHeader/ChartHeader";
import {getAnnotations} from "./chartFunctions";
import MetricView, {AMetricView, KvpMetricView} from "../../models/MetricView";
import {time} from "react-timeline";
import {observer} from "mobx-react";
import Project from "../../../skins/cu-boulder/models/Project";

Chart.register(annotationPlugin);


export default class StackedAreaChart extends DashiChart {
    @observable
    protected metricView: AMetricView;

    referenceLine: number = 0;
    constructor(props: any) {
        super(props);
        this.metricView = new MetricView((p) => [0]);
        makeObservable(this);
    }


    @override
    get data() {
        return this.metricView.totalsByType;
    }

    @computed
    get timeIncrement() {
        const {periodScale} = stores.config;
        return periodScale;
    }

    @computed
    get xLabels(): string[] | null {
        return null;//use default
    }

    @computed
    get scrubberInfo(): string | null {
        const total = this.metricView.current;
        return this.formatValue(total);
    }

    @computed
    get scrubber() {
        const {app, config} = stores;

        return {
            value: app.scrubber / this.timeIncrement,
            info: this.scrubberInfo,
        };
    }

    render() {
        const {style} = this.props;
        const {app} = stores;

        const data = {
            labels: this.xLabels || app.periodLabels,
            datasets: [],
            axes: {x: {title: this.x}, y: {title: this.y}}
        };

        //Any KVP in the data may introduce new keys. Get and deduplicate keys from all objects.
        // @ts-ignore
        const keys = [...new Set(this.data.map((obj, i) => {
            return Object.keys(obj)
        }).reduce(function (pre, cur) {
            return pre.concat(cur);
        }))]

        keys.forEach(key => {
            // @ts-ignore
            data.datasets.push(formatLineDataSet(this.colors[key], key, this.data.map(p => p[key])));
        });

        const options = {
            plugins: {
                legend: {
                    display: false,
                },
                annotation: {
                    annotations: getAnnotations(this.scrubber, this.referenceLine),
                },
                tooltip: {
                    callbacks: {
                        label: (context: any) => {
                            return this.formatValue(context.raw);
                        }
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    title: {
                        text: this.x,
                        display: false,
                    }
                },
                y: {
                    beginAtZero: true,
                    ticks: {
                        callback: (value:number) => {
                            return this.formatValue(value);
                        }
                    },
                    stacked: true,
                    title: {
                        text: this.y,
                        display: true,
                    }
                },
            },
        };

        return (
            <div className="DashiChart StackedAreaChart" style={style}>
                <ChartHeader data={data} title={this.static.title}/>

                <div>
                    <Line
                        options={merge(options, this.options)}
                        data={data}
                        //@ts-ignore
                        plugins={[ChartAnnotation]}
                    />

                </div>
            </div>
        );
    }

}


@observer
export class AnnualStackedAreaChart extends StackedAreaChart {

    @override
    get timeIncrement() {
        return time.YEAR;
    }

    // @override
    // get currentX() {
    //     const {app} = stores;
    //     return Math.floor(app.currentPeriod.i / 12);
    // }


    @override
    get scrubberInfo(): string | null {
        const {app} = stores;

        //Note: not all annual charts will be cumulative. Other use cases should override this
        const total = this.metricView.cumulativeCurrent;
        const activeYear = Math.floor(app.scrubber / time.YEAR);

        return this.xLabels[activeYear] + ': ' + this.formatValue(total);
    }


    @override
    get xLabels() {
        const {startYear, periodScale, totalPeriods} = stores.config;
        const years = Math.floor((totalPeriods * periodScale) / time.YEAR);

        return [...new Array(years)].map((_, i) => `'${i + startYear - 2000}`);
    }
}
