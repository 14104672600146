import NumberFormat from 'react-number-format';

import DashiProject from '../models/Project';
import DashiMetric, { MetricBehavior } from '../models/Metric';


export default class DashiCostMetric extends DashiMetric {

    name = 'Cost';

    displayUnits(value: number) {
        return `$ ${value}`;
    }

    generatePayload(cost: number = 0) {
        return { cost };
    }

    getValue = (behavior: MetricBehavior, payload: any, index: number) => {
        if (behavior === MetricBehavior.During && index === 0) {
            return payload;
        }

        return { cost: 0 };
    }

    renderSettings(project: DashiProject) {
        const { cost } = project.metrics[this.name];
        const spaceType = project.metrics['SpaceType'];

        return (
            <div className="CostMetricSettings ProjectSettings-section">
                <div className="ProjectSettings-entry">
                    <label htmlFor="ProjectCost">Project Cost</label>
                    <div className="ProjectSettings-input">
                        <div className="labelled-input">
                            <label htmlFor="ProjectCost">$</label>
                            <NumberFormat
                                id="ProjectCost"
                                thousandSeparator={true}
                                value={cost}
                                onValueChange={e => project.setMetric(this.name, this.generatePayload(e.floatValue))}
                            />
                        </div>

                        {spaceType && (
                            <div className="ProjectSettings-cell">
                                <label htmlFor="">Cost per SF/FT</label>
                                <div className="cell-value">
                                    {(
                                        project.type === 'Demolition' ? (
                                            // @ts-ignore
                                            cost / Object.values(spaceType.before).reduce((a: number,b: number) => a + b, 0)
                                        ) : (
                                            // @ts-ignore
                                            cost / Object.values(spaceType.after).reduce((a: number, b: number) => a + b, 0)
                                        )
                                    ).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

}
